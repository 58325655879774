<style>
v-list-item-title{
    color:white,
};
.rounded{
    border-radius:60px;
}
</style>
<template>
    <v-navigation-drawer 
    app permanent style="position:fixed;top:0;left:0"
    absolute 
    hide-overlay>
        <ANotAllowed :notAllowed="notAllowed" @close="closeNotAllowed">

        </ANotAllowed>
        <v-list  >
            <v-list-item-group>
                <v-list-item @click="redirectHomePage">
                    <div class="d-flex flex-row my-4">
                        <div class="d-flex flex-column">
                            <v-img
                            @click="redirectHomePage"
                            contain
                            height="auto"
                            width="auto"
                            src="/images/Favicon.png"
                            max-height="50"
                            max-width="50">
                            </v-img> 
                        </div>
                        <div class="d-flex flex-column px-3">
                            <div class="d-flex flex-row my-0 py-0 font-weight-bold text-h7">
                                TaxPod Vision
                            </div>
                            <div class="d-flex flex-row my-0 py-0">
                                <v-chip color="primary">
                                v {{ $version }}
                                </v-chip>
                            </div>

                        </div>

                    </div>
                </v-list-item>

                <v-list-item  class="white mx-4 rounded" @click="redirectHomePage" :to="{name:'PageHomeDashboard'}">
                    <v-icon color="primary" ligth size="20" dense>
                        mdi-view-dashboard
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        Dashboard
                    </v-list-item-title>
                </v-list-item>
            <v-list-item  v-if="this.$store.getters.getRole.includes('developer')" class="white mx-4 rounded" :to="{name:'PageDeveloperDashboard'}">
                    <v-icon color="primary" ligth size="20" dense>
                        mdi-view-dashboard
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        Developer site
                    </v-list-item-title>
                </v-list-item>

                <v-list-item class="white mx-4 rounded" @click="redirectPersonalTask" :to="{name:'PageTaskListUser',params:{id:this.$store.getters.getUserId}}">
                    <v-icon color="primary" light size="20" dense>
                        bi-clipboard-check
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        <span>
                            My tasks
                        </span>
                        <v-chip class="ma-2" color="primary white--text">
                            {{ this.notification.totalTask }}
                        </v-chip>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item class="white mx-4 rounded" @click="redirectStaff" :to="{name:'PageAdminStaff'}">
                    <v-icon color="primary" size="20" dense>
                        bi-people
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        Staffs
                    </v-list-item-title>
                </v-list-item>
                <v-list-item class="white mx-4 rounded" @click="redirectPic" :to="{name:'PagePicList'}"
                    v-if="$permission.allowedAdminOperationRole($store.getters.getRole)">
                    <v-icon color="primary" size="20" dense>
                        bi-person
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        PIC
                    </v-list-item-title>
                </v-list-item>
                <v-list-item class="white mx-4 rounded"  @click="showNotAllowed" 
                    v-else>
                    <v-icon color="primary" size="20" dense>
                        bi-person
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        PIC
                    </v-list-item-title>
                </v-list-item>

                <v-list-item class="white mx-4 rounded" @click="redirectCompanies" :to="{name:'PageCompaniesList'}">
                    <v-icon color="primary" size="20" dense>
                        bi-briefcase
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        Companies
                    </v-list-item-title>
                </v-list-item>

                <v-list-item class="white mx-4 rounded" @click="redirectUser" :to="{name:'PageUserList'}">
                    <v-icon color="primary" size="20" dense>
                        bi-person
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        Users
                    </v-list-item-title>
                </v-list-item>
                <v-list-group :value="true" class="justify-center"
                    v-if="$permission.allowedAdminOperationRole($store.getters.getRole)">
                    <template v-slot:activator>
                        <v-list-item class="pl-3 pr-0 ml-0 mr-0">
                                <v-icon color="primary" ligth size="20" dense>
                                    bi-file-text-fill
                                </v-icon>
                                <v-list-item-title class="ml-2">
                                    Subscriptions
                                </v-list-item-title>
                            </v-list-item>
                    </template>
                    <div>
                        <v-list-item
                            class="white ml-8 mr-3"
                            :to="{name:'PageSubscriptionScheduler'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-text-fill
                            </v-icon>
                            <v-list-item-title class="ml-3" style="font-size:15px">
                                Scheduled
                            </v-list-item-title>
                        </v-list-item>
                    </div>

                        <!-- <v-list-item  class="white ml-8 mr-3" :to="{name:'PageNewCompaniesSubscriptionList'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-text-fill
                            </v-icon>
                            <v-list-item-title class="ml-3" style="font-size:15px">
                                First Subscriptions
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" :to="{name:'PageNewRenewalReport'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-text-fill
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Renewal
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                    <div v-else>
                            <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed">
                                <v-icon color="primary" size="20" dense>
                                    bi-file-text-fill
                                </v-icon>
                                <v-list-item-title class="ml-3" style="font-size:15px">
                                    First Subscriptions
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed">
                                <v-icon color="primary" size="20" dense>
                                    bi-file-text-fill
                                </v-icon>
                                <v-list-item-title class="ml-3">
                                    Renewal
                                </v-list-item-title>
                            </v-list-item>
                    </div> -->
                </v-list-group>

                <div
                v-if="this.$store.getters.getFeatures.attendancelist!=null">
                    <v-list-item
                    :to="{name:'PageWebinarList'}"
                    class="white mx-4 rounded"
                    v-if="this.$store.getters.getFeatures.attendancelist.status==1 || (this.$store.getters.getFeatures.attendancelist.beta==1 && this.$store.getters.getRole.includes('developer'))">
                        <v-icon
                        color="primary"
                        size="20"
                        dense>
                        bi-display
                        </v-icon>
                        <v-list-item-title
                            class="ml-3">
                            Webinars
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-else
                        @click="showNotAllowed"
                        class="white mx-4 rounded">
                        <v-icon
                            color="primary"
                            size="20"
                            dense>
                            bi-display
                        </v-icon>
                        <v-list-item-title
                            class="ml-3">
                            Webinars
                        </v-list-item-title>
                    </v-list-item>
                </div>
                <div
                    v-else>
                    <v-list-item
                        @click="showNotAllowed"
                        class="white mx-4 rounded">
                        <v-icon
                            color="primary"
                            size="20"
                            dense>
                            bi-display
                        </v-icon>
                        <v-list-item-title
                            class="ml-3">
                            Webinars
                        </v-list-item-title>
                    </v-list-item>
                </div>



                <v-list-group :value="true" v-if="allowedRolesReport.some(role=>this.$store.getters.getRole.includes(role))">
                    <template v-slot:activator>
                        <v-list-item>
                            <v-icon color="primary" light size="20" dense>
                                bi-person-square
                            </v-icon>
                            <span class="ml-2">
                                Referral
                            </span>
                        </v-list-item>
                    </template>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" :to="{name:'PageRewardList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-gift
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Reward
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" :to="{name:'PageClaimRewardList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-person-square
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Claim
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" :to="{name:'PageTransactionList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-layout-text-sidebar
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Points
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" :to="{name:'PageDemonstrationList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-layout-text-sidebar
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Demo Request
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
                


                <v-list-group :value="true" v-else>
                    <template v-slot:activator>
                        <v-list-item>
                            <v-icon color="primary" light size="20" dense>
                                bi-person-square
                            </v-icon>
                            <span class="ml-2">
                                Referral
                            </span>
                        </v-list-item>
                    </template>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" @click="showNotAllowed">
                        <v-icon color="primary" size="20" dense>
                            bi-gift
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Reward
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" @click="showNotAllowed">
                        <v-icon color="primary" size="20" dense>
                            bi-person-square
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Claim
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" @click="showNotAllowed">
                        <v-icon color="primary" size="20" dense>
                            bi-layout-text-sidebar
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Transaction
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" :to="{'name':'PageDemonstrationList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-layout-text-sidebar
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Demo Request
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>


                <v-list-group :value="true" v-if="allowedRolesReport.some(role=>this.$store.getters.getRole.includes(role))">
                    <template v-slot:activator>
                        <v-list-item>
                            <v-icon color="primary" light size="20" dense>
                                bi-calendar3-range
                            </v-icon>
                            <span class="ml-2">
                                Survey
                            </span>
                        </v-list-item>
                    </template>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" :to="{name:'PageUserDetailSurveyList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-calendar3-range
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            User Detail
                        </v-list-item-title>
                    </v-list-item>
                    <div
                    v-if="this.$store.getters.getFeatures.survey
                    !=null">
                        <v-list-item class="white mx-4 rounded ml-8 mr-3" :to="{name:'PageFeedbackSurveyList'}"
                        v-if="this.$store.getters.getFeatures.survey.status==1 || (this.$store.getters.getFeatures.survey.beta==1 && this.$store.getters.getRole.includes('developer'))">
                            <v-icon color="primary" size="20" dense>
                                bi-calendar3-range
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Feedback
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="white mx-4 rounded ml-8 mr-3" :to="{name:'PageFinanceSurveyList'}"
                        v-if="this.$store.getters.getFeatures.survey.status==1 || (this.$store.getters.getFeatures.survey.beta==1 && this.$store.getters.getRole.includes('developer'))">
                            <v-icon color="primary" size="20" dense>
                                bi-calendar3-range
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Finance
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list-group>


                <v-list-group :value="true" v-else>
                    <template v-slot:activator>
                        <v-list-item>
                            <v-icon color="primary" light size="20" dense>
                                bi-calendar3-range
                            </v-icon>
                            <span class="ml-2">
                                Survey
                            </span>
                        </v-list-item>
                    </template>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" @click="showNotAllowed">
                        <v-icon color="primary" size="20" dense>
                            bi-calendar3-range
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            User Detail
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" @click="showNotAllowed">
                        <v-icon color="primary" size="20" dense>
                            bi-calendar3-range
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Feedback
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white mx-4 rounded ml-8 mr-3" @click="showNotAllowed">
                        <v-icon color="primary" size="20" dense>
                            bi-calendar3-range
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Finance
                        </v-list-item-title>
                    </v-list-item>
                    
                </v-list-group>

 
                <v-list-group :value="true">
                    <template v-slot:activator> 
                            <v-list-item class="">
                                <v-icon color="primary" ligth size="20" dense>
                                    bi-paperclip   
                                </v-icon>
                                <span class="ml-2">
                                    Attachments
                                </span>
                            </v-list-item>

                    </template>
                    <v-list-item class="white ml-8 mr-3"  @click="redirectReceipt" :to="{name:'PageReceiptList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-receipt
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Proof of Payment
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white ml-8 mr-3" @click="redirectInvoice" :to="{name:'PageInvoiceList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-receipt
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Invoices
                        </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                        v-if="allowedRolesXero.some(role=>this.$store.getters.getRole.includes(role))"
                        class="white ml-8 mr-3"
                        :to="{name:'PageInvoiceXeroList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-receipt
                        </v-icon>
                        <v-list-item-title
                            class="ml-3">
                            Invoices
                        </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item class="white ml-8 mr-3" :to="{name:'PageRefundList'}">
                        <v-icon color="primary" size="20" dense>
                            bi-receipt
                        </v-icon>
                        <v-list-item-title class="ml-3">
                            Refund
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

              
                <!-- <v-list-group :value="true">
                    <template v-slot:activator>
                        <v-list-item class="">
                                <v-icon color="primary" ligth size="20" dense>
                                    bi-card-heading 
                                </v-icon>
                                <span class="ml-2">
                                    Templates
                                </span>
                            </v-list-item>
                    </template>
                    <div>
                        <v-list-item class="white ml-8 mr-3" :to="{name:'PageWhatssapTemplateList'}">
                            <v-icon color="primary" size="20" dense>
                                bi-card-text
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Whatsapp
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="white ml-8 mr-3" @click="showNotAllowed">
                            <v-icon color="primary" size="20" dense>
                                bi-card-text
                            </v-icon>
                            <v-list-item-title class="ml-3" >
                                Email
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list-group> -->

               
                <v-list-group :value="true">
                    <template v-slot:activator> 
                            <v-list-item class="">
                                <v-icon color="primary" ligth size="20" dense>
                                    bi-file-earmark-post-fill
                                </v-icon>
                                <v-list-item-title class="mx-3">
                                    Reports
                                </v-list-item-title>

                            </v-list-item>
                    </template>
                    <div v-if="allowedRolesReport.some(role=>this.$store.getters.getRole.includes(role))">
                        <v-list-item 
                            class="white ml-8 mr-3" 
                            :to="{name:'PageCompaniesReport'}">
                            <v-icon
                                color="primary"
                                size="20"
                                dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title
                                class="ml-3">
                                Companies
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                           
                            class="white ml-8 mr-3"
                            :to="{name:'PageMarketingReport'}">
                            <v-icon
                                color="primary"
                                size="20"
                                dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title
                                class="ml-3">
                                Marketing
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="white ml-8 mr-3" :to="{name:'PageReportRenewalList'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Renewal 
                            </v-list-item-title>
                        </v-list-item >
                        <!-- <v-list-item  class="white ml-8 mr-3" :to="{name:'PageNewCompaniesSubscriptionList'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Subscriptions
                            </v-list-item-title>
                        </v-list-item> -->
                        <!-- <v-list-item  class="white ml-8 mr-3" :to="{name:'PageNewRenewalReport'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                New Renewal
                            </v-list-item-title>
                        </v-list-item> -->
                        <v-list-item  class="white ml-8 mr-3" :to="{name:'PageReApplyAAECoupon'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Failed Coupons
                            </v-list-item-title>
                        </v-list-item>                     

                        <v-list-item class="white ml-8 mr-3" :to="{name:'PageAnalysis'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Analysis
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item class="white ml-8 mr-3" :to="{name:'PageDaysAnalysis'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                7 Days
                            </v-list-item-title>
                        </v-list-item>


                        <v-list-item  class="white ml-8 mr-3" :to="{name:'PageLoginAnalysis'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3" style="white-space:normal">
                                Login Analysis
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item class="white ml-8 mr-3" :to="{name:'PagePICReport'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                PIC
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="white ml-8 mr-3" :to="{name:'PageUnassignedCompanies'}">
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3" style="white-space:normal">
                                    Unassigned Companies
                            </v-list-item-title>
                        </v-list-item>

                    </div>
                    <div v-else>
                        <v-list-item
                            @click="showNotAllowed"
                            class="white ml-8 mr-3" >
                            <v-icon
                                color="primary"
                                size="20"
                                dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title
                                class="ml-3">
                                Companies
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="this.$store.getters.getRole.includes('marketing')"
                            class="white ml-8 mr-3"
                            :to="{name:'PageMarketingReport'}">
                            <v-icon
                                color="primary"
                                size="20"
                                dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title
                                class="ml-3">
                                Marketing
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-else
                            class="white ml-8 mr-3"
                            @click="showNotAllowed">
                            <v-icon
                                color="primary"
                                size="20"
                                dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title
                                class="ml-3">
                                Marketing
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed" >
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Renewal
                            </v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed" >
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                New Subscription
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed" >
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Failed Coupons
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed" >
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Analysis
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed" >
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                7 Days
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed" >
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Login Analysis
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed" >
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                PIC
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed" >
                            <v-icon color="primary" size="20" dense>
                                bi-file-earmark-post
                            </v-icon>
                            <v-list-item-title class="ml-3">
                                Unassigned Companies
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list-group>
                <!-- <v-list-group :value="true" class="justify-center">
                    <template v-slot:activator>
                        <v-list-item class="pl-3 pr-0 ml-0 mr-0">
                                <v-icon color="primary" ligth size="20" dense>
                                    bi-file-earmark-bar-graph
                                </v-icon>
                                <v-list-item-title class="ml-2">
                                    Analysis
                                </v-list-item-title>
                            </v-list-item>
                    </template>
                    <div v-if="allowedRolesReport.some(role=>this.$store.getters.getRole.includes(role))">
                        <v-list-item  class="white ml-8 mr-3" :to="{name:'PageActiveUsersAnalysis'}">
                            <v-icon color="primary" size="20" dense>
                                bi-bar-chart
                            </v-icon>
                            <v-list-item-title class="ml-3" style="font-size:15px">
                                Active User
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" :to="{name:'PageActiveCompaniesAnalysis'}">
                            <v-icon color="primary" size="20" dense>
                                bi-bar-chart
                            </v-icon>
                            <v-list-item-title class="ml-3" style="font-size:15px">
                                Active Company
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" :to="{name:'PageRenewalAnalysis'}">
                            <v-icon color="primary" size="20" dense>
                                bi-bar-chart
                            </v-icon>
                            <v-list-item-title class="ml-3" style="font-size:15px">
                                Renewal
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" :to="{name:'PageSalesAnalysis'}">
                            <v-icon color="primary" size="20" dense>
                                bi-bar-chart
                            </v-icon>
                            <v-list-item-title class="ml-3" style="font-size:15px">
                                Sales
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item  class="white ml-8 mr-3" :to="{name:'PageRevenueAnalysis'}">
                            <v-icon color="primary" size="20" dense>
                                bi-bar-chart
                            </v-icon>
                            <v-list-item-title class="ml-3" style="font-size:15px">
                                Revenue
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                    <div v-else>
                            <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed">
                                <v-icon color="primary" size="20" dense>
                                    bi-bar-chart
                                </v-icon>
                                <v-list-item-title class="ml-3" style="font-size:15px">
                                    Active User
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed">
                                <v-icon color="primary" size="20" dense>
                                    bi-bar-chart
                                </v-icon>
                                <v-list-item-title class="ml-3" style="font-size:15px">
                                    Active Company
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed">
                                <v-icon color="primary" size="20" dense>
                                    bi-bar-chart
                                </v-icon>
                                <v-list-item-title class="ml-3" style="font-size:15px">
                                    Sales
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item  class="white ml-8 mr-3" @click="showNotAllowed">
                                <v-icon color="primary" size="20" dense>
                                    bi-bar-chart
                                </v-icon>
                                <v-list-item-title class="ml-3" style="font-size:15px">
                                    Revenue
                                </v-list-item-title>
                            </v-list-item>
                    </div>
                </v-list-group> -->
                <div
                v-if="this.$store.getters.getFeatures.dropdown!=null">
                    <v-list-item
                    :to="{name:'PageDropdownList'}"
                    class="white mx-4 rounded"
                    v-if="this.$store.getters.getFeatures.dropdown.status==1 || (this.$store.getters.getFeatures.dropdown.beta==1 && this.$store.getters.getRole.includes('developer'))">
                        <v-icon
                        color="primary"
                        size="20"
                        dense>
                        bi-menu-app
                        </v-icon>
                        <v-list-item-title
                            class="ml-3">
                            Dropdown
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-else
                        @click="showNotAllowed"
                        class="white mx-4 rounded">
                        <v-icon
                            color="primary"
                            size="20"
                            dense>
                            bi-menu-app
                        </v-icon>
                        <v-list-item-title
                            class="ml-3">
                            Dropdown
                        </v-list-item-title>
    
                    </v-list-item>
                </div>
                <div
                    v-else>
                    <v-list-item
                        @click="showNotAllowed"
                        class="white mx-4 rounded">
                        <v-icon
                            color="primary"
                            size="20"
                            dense>
                            bi-menu-app
                        </v-icon>
                        <v-list-item-title
                            class="ml-3">
                            Dropdown
                        </v-list-item-title>
    
                    </v-list-item>

                </div>



                <v-list-item
                class="white  mx-4 rounded " @click="redirectManual" :to="{name:'PageManualList'}">
                    <v-icon color="primary" size="20" dense>
                       bi-file-text
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        Manuals
                    </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                v-if="this.$store.getters.getRole=='developer'"
                class="white  mx-4 rounded "  :to="{name:'PageDeveloperRenewalList'}">
                    <v-icon color="primary" size="20" dense>
                       bi-file-text
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        Renewal
                    </v-list-item-title>
                </v-list-item> -->
                
                <!-- <v-list-item
                class="white" @click="redirectReceiptInvoice">
                    <v-icon color="primary" size="20" dense>
                       fa fa-book
                    </v-icon>
                    <v-list-item-title class="ml-3">
                        Invoice & Receipt
                    </v-list-item-title>
                </v-list-item> -->
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';
import ANotAllowed from '../../components/common/ANotAllowed.vue';

export default{
    components:{
        ANotAllowed,
    },
    props:[
    'drawer'
    ],  
    computed:mapState({
        notification:state=>state.notification,
        countAlias:'notification',
    }),
    mounteds(){
        this.role = this.$store.getters.getRole;
    },
    data:()=>({
        role:null,
        allowedRolesXero:['developer','vision_admin','operation'],
        allowedRolesReport: ["developer",'vision_admin',"operation","sales_manager","customer_service"],
        notAllowed:false,
       

    }),
    methods:{

        redirectPersonalTask(){
            this.$store.commit('updateCurrentTitle','My Tasks')
            this.$router.push({name:'PageTaskListUser',params:{id:this.$store.getters.getUserId}});
        },
        redirectHomePage(){
            this.$store.commit('updateCurrentTitle','Dashboard')
            this.$router.push({name:'PageHomeDashboard'});
        },
        redirectHomePageDeveloper(){
            this.$router.push({name:'PageDeveloperDashboard'});  
        },
        redirectCompanies()
        {
            this.$store.commit('updateCurrentTitle','Companies')
            this.$router.push({name:'PageCompaniesList'});
        },
        redirectSalesPerson()
        {
            this.$store.commit('updateCurrentTitle','Staffs');
            this.$router.push({name:'PageAdminSalesperson'});
        },
        redirectStaff()
        {
            this.$store.commit('updateCurrentTitle','Staffs');

            this.$router.push({name:'PageAdminStaff',params:{"staff":"all"}});
        },
        redirectTeam(){
            this.$store.commit('updateCurrentTitle','Teams');

            this.$router.push({
            name:'PageTeamList'
            })
        },
        redirectUser(){
            this.$store.commit('updateCurrentTitle','Users');

            this.$router.push({name:'PageUserList'});
        },
        redirectPic(){
            this.$store.commit('updateCurrentTitle','Person-in-charge');

            this.$router.push({name:'PagePicList'});
        },
        redirectReceiptInvoice(){
            this.$router.push({name:'PageAttachmentList'});
        },
        redirectReceipt(){
            this.$store.commit('updateCurrentTitle','Receipts');

            this.$router.push({name:'PageReceiptList'});
        },
        redirectInvoice(){
            this.$store.commit('updateCurrentTitle','Invoices');

            this.$router.push({name:'PageInvoiceList'});
        },
        redirectManual(){
            this.$store.commit('updateCurrentTitle','Manuals');

            this.$router.push({name:'PageManualList'});
        },
        showNotAllowed(){
            this.notAllowed=true;
        },
        closeNotAllowed(){
            this.notAllowed = false;
        }
    }
}
</script>